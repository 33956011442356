.container-footer * {
  overflow-x: hidden;
}

.container-footer .input-email {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  padding: 6px;
  padding-left: 60px;
}

.tooltip-link {
  font-size: 14px;
  padding: 6px 10px;
  transition: 0.2s;
  display: block;
  text-decoration: none;
  color: #848484;
  border-bottom: 1px solid #84848495;
}

.tooltip-link:hover {
  color: rgb(242, 155, 68);
}

/* Large Screen */
@media screen and (min-width: 600px) {
  .container-footer {
    /* height: 779px; */
    background: #1b1f2e;
    width: 100%;
    padding: 0px 10px;
    background-image: url(https://gaviaspreview.com/wp/krowd/wp-content/uploads/2015/12/bg-footer.png);
    background-repeat: no-repeat;
  }

  .container-content-footer {
    padding: 40px 0px 90px;
    border-bottom: 1px solid #333333;
    display: flex;
    justify-content: space-around;
  }

  .logo-campus {
    /* height: 185px; */
    width: 212px;
    margin-bottom: -6px;
  }

  .logo-org {
    width: 250px;
    margin-bottom: 10px;
  }

  .text-footer {
    color: #8d91a2;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    width: 308px;
    line-height: 230%;
  }

  .title-footer {
    color: #ffffff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    width: 343px;
  }

  .footer-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none;
    transition: 0.2s;
  }

  .footer-menu:hover {
    color: #fd9344;
  }

  .icon-footer {
    color: #ce4c36;
    background-image: #ce4c36;
    height: px;
    width: 50px;
  }

  .join-text {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    margin-top: 20px;
  }

  .container-mail {
    padding: 3px;
    width: 343px;
    height: 74px;
    border: 1px solid rgba(124, 120, 120, 0.432);
    border: 1px solid rgba(252, 249, 249, 0.082);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .form-email,
  .form-email:focus {
    background: #1b1f2e;
    border: none;
    border-radius: 0;
    color: #ffffff;
  }

  .container-contact,
  .container-sitemap {
    margin-top: 30px;
    margin-left: 30px;
  }

  .social-media {
    margin-top: 40px !important;
    margin-left: -10px;
    overflow: hidden;
    width: 100%;
  }

  .icon-social {
    color: #1b1f2e;
    background: #ffffff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transition: transform 0.3s cubic-bezier(0, 0.64, 0.19, 2.85);
  }

  .icon-social:hover {
    transform: scale(0.9);
    background: #fd9344;
    color: inherit;
  }

  .social-media {
    margin-top: 15px;
  }

  .copyright {
    /* margin: 30px auto; */
    border-top: 1px solid #8d91a228;
    padding: 40px;
    display: flex;
    justify-content: center;
  }

  .col-icon-footer {
    display: flex;
    align-items: center;
  }
}

/* Mobile Screen */
@media screen and (max-width: 600px) {
  .container-footer {
    min-height: 1000px;
    background: #1b1f2e;
    width: 100%;
    padding: 0px 20px 10px;
  }

  .container-content-footer {
    padding: 40px 0px 20px;
    border-bottom: 1px solid #333333;
  }

  .logo-campus {
    height: 185px;
    width: 262px;
  }

  .logo-org {
    width: 231px;
    margin-bottom: 20px;
  }

  .text-footer {
    color: #8d91a2;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    width: 100%;
    line-height: 200%;
  }

  .title-footer {
    color: #ffffff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    width: 343px;
  }

  .footer-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .icon-footer {
    color: #ce4c36;
    background-image: #ce4c36;
    height: px;
    width: 50px;
  }

  .join-text {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
  }

  .container-mail {
    padding: 3px;
    width: 343px;
    height: 74px;
    border: 1px solid rgba(124, 120, 120, 0.432);
    border: 1px solid rgba(252, 249, 249, 0.082);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .form-email,
  .form-email:focus {
    background: #1b1f2e;
    border: none;
    border-radius: 0;
    color: #ffffff;
  }

  .container-contact,
  .container-sitemap {
    margin-top: 50px;
    margin-left: 0px;
  }

  .social-media {
    width: 100%;
  }

  .icon-social {
    color: #1b1f2e;
    background: #ffffff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .social-media {
    margin-top: 15px;
  }

  .container-join {
    margin-top: 50px;
  }

  .copyright {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .copyright span {
    font-size: small;
  }
}

@media screen and (max-width: 376px) {
  .container-footer {
    min-height: 1000px;
    background: #1b1f2e;
    width: 100%;
    padding: 0px 20px 10px;
  }

  .container-content-footer {
    padding: 40px 0px 20px;
    border-bottom: 1px solid #333333;
  }

  .logo-campus {
    height: 185px;
    width: 262px;
  }

  .logo-org {
    width: 231px;
    height: 138px;
  }

  .text-footer {
    color: #8d91a2;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    width: 308px;
    line-height: 200%;
  }

  .title-footer {
    color: #ffffff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    width: 343px;
  }

  .footer-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .icon-footer {
    color: #ce4c36;
    background-image: #ce4c36;
    height: px;
    width: 50px;
  }

  .join-text {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
  }

  .container-mail {
    padding: 3px;
    width: 320px;
    height: 74px;
    border: 1px solid rgba(124, 120, 120, 0.432);
    border: 1px solid rgba(252, 249, 249, 0.082);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .form-email,
  .form-email:focus {
    background: #1b1f2e;
    border: none;
    border-radius: 0;
    color: #ffffff;
  }

  .container-contact,
  .container-sitemap {
    /* margin-top: 50px; */
    margin-left: 0px;
  }

  .social-media {
    width: 100%;
  }

  .icon-social {
    color: #1b1f2e;
    background: #ffffff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .social-media {
    margin-top: 15px;
  }

  .container-join {
    margin-top: 50px;
  }

  .copyright {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .copyright span {
    font-size: small;
  }
}

@media screen and (max-width: 361px) {
  .container-footer {
    min-height: 1000px;
    background: #1b1f2e;
    width: 100%;
    padding: 0px 20px 10px;
  }

  .container-content-footer {
    padding: 40px 0px 20px;
    border-bottom: 1px solid #333333;
  }

  .logo-campus {
    height: 185px;
    width: 262px;
  }

  .logo-org {
    width: 231px;
    height: 138px;
  }

  .text-footer {
    color: #8d91a2;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    width: 308px;
    line-height: 200%;
  }

  .title-footer {
    color: #ffffff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    width: 343px;
  }

  .footer-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .icon-footer {
    color: #ce4c36;
    background-image: #ce4c36;
    height: px;
    width: 50px;
  }

  .join-text {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
  }

  .container-mail {
    padding: 3px;
    width: 300px;
    height: 74px;
    border: 1px solid rgba(124, 120, 120, 0.432);
    border: 1px solid rgba(252, 249, 249, 0.082);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .form-email,
  .form-email:focus {
    background: #1b1f2e;
    border: none;
    border-radius: 0;
    color: #ffffff;
  }

  .container-contact,
  .container-sitemap {
    margin-top: 50px;
    margin-left: 0px;
  }

  .social-media {
    width: 100%;
    overflow: hidden;
  }

  .icon-social {
    color: #1b1f2e;
    background: #ffffff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .social-media {
    margin-top: 15px;
  }

  .container-join {
    margin-top: 50px;
  }

  .copyright {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .copyright span {
    font-size: small;
  }
}
