@media screen and (min-width: 1000px) {
  .form-row {
    margin-top: 50px;
  }
  .container-form {
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .container-card-signup-detail {
    height: fit-content;
  }
}

/* Mobile Size */
@media screen and (max-width: 420px) {
  .container-form {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .container-card-signup-detail {
    height: 100%;
  }
}

/* Mobile Size */
@media screen and (max-width: 376px) {
  .container-form {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .container-card-signup-detail {
    height: 100%;
  }
}

@media screen and (max-width: 360px) {
  .container-form {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .container-card-signup-detail {
    height: 100%;
  }
}
