/* Large Screen */
@media screen and (min-width: 480px) {
  .img-title {
    height: 338px;
    width: 100%;
  }
}

/* Mobile Screen */
@media screen and (max-width: 425px) {
  .img-title {
    height: 126px;
    width: 100%;
  }
}

@media screen and (max-width: 376px) {
  .img-title {
    height: 126px;
    width: 100%;
  }
}

@media screen and (max-width: 361px) {
  .img-title {
    height: 126px;
    width: 100%;
  }
}
