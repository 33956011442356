.container-card-signup-detail {
  padding: 20px 70px 30px 70px;
  margin: auto;
  background: #f0f4ff;
}

.card-signup-detail {
  box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 10%);
}

.icon-next {
  color: #dedede;
}

@media screen and (min-width: 1000px) {
  .stepper {
    width: 100%;
  }
  .step-label {
    font-family: "Museo Sans 700", Arial;
    color: "#AAAAAA";
    font-size: 14px;
  }

  .logo-signup-page {
    max-height: 100px;
  }

  .card-signup-detail {
    padding: 0px 50px;
    min-height: 800px;
  }

  .btn-back {
    width: 10rem;
    margin-right: 5rem;
  }
  .btn-next {
    width: 150px;
  }
}

/* Mobile Size */
@media screen and (max-width: 420px) {
  .stepper {
    width: 100%;
  }
  .step-label {
    display: none;
  }

  .logo-signup-page {
    max-height: 80px;
  }

  .card-signup-detail {
    padding: 0px 20px;
    min-height: 850px;
  }
}

/* Mobile Size */
@media screen and (max-width: 376px) {
  .stepper {
    width: 100%;
  }
  .step-label {
    display: none;
  }

  .logo-signup-page {
    max-height: 80px;
  }

  .card-signup-detail {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 360px) {
  .stepper {
    width: 100%;
  }
  .step-label {
    display: none;
  }

  .logo-signup-page {
    max-height: 80px;
  }

  .card-signup-detail {
    padding: 0px 20px;
  }
}
