@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
* {
  font-family: "Poppins", sans-serif !important;
}

body {
  margin: 0;
  margin-top: 150px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-group {
  margin-bottom: 14px;
}

.form-group label {
  font-weight: 600;
  color: #4a4c46;
  font-size: 16px;
}

.form-group input,
.form-group textarea {
  border-radius: 6px;
  transition: 0.2s;
  border: 1px solid #b0b1ae !important;
}

.form-group input {
  height: 41px;
}

.form-group input:hover,
.form-group input:focus,
.form-group textarea:hover,
.form-group textarea:focus {
  background-color: #ffeee1;
  outline: none !important;
  box-shadow: nonw !important;
  outline-offset: none !important;
}

.form-group .form-control:focus {
  box-shadow: none !important;
}

.form-group input:disabled:hover,
.form-group textarea:disabled:hover {
  background-color: #e9ecef;
}

.form-group textarea:hover,
.form-group textarea:focus {
  border: 1px solid #b0b1ae !important;
}

.MuiCalendarPicker-root .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  color: #fff !important;
  background-color: #fd9344 !important;
}

.MuiTypography-root.MuiTypography-h4.title-section {
  color: #4a4c46;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 8px;
}

.section-body {
  padding: 0px 35px;
}

@media (max-width: 600px) {
  body {
    margin-top: 80px;
  }
  .MuiTypography-root.MuiTypography-h4.title-section {
    font-size: 18px;
    line-height: 150%;
  }
  .form-group label.form-label {
    font-size: 14px;
    font-weight: 500;
  }

  .section-body {
    padding: 0px;
  }
}
iframe {
  z-index: -1000000 !important;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #FD9344;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 6px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #FD9344, 0 0 5px #FD9344;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #FD9344;
  border-left-color: #FD9344;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.navbar-container {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1000000;
}

.navbar-bottom {
  padding-left: 32px;
  padding-right: 90px;
  height: 100px;
}

.navbar-bottom .container-icon-hotline img {
  margin-right: 9px;
  width: 44px;
  height: 44px;
  margin-top: 20px;
}

.navbar-bottom .container-icon-hotline .info-hotline {
  font-size: 14px;
  color: #464c46;
}

.navbar-bottom .container-icon-hotline .content-info {
  color: #090c03;
  font-size: 14px;
}

/* Large Screen */
@media screen and (min-width: 600px) {
  .container-nav-info {
    width: 100%;
    height: 52px;
    background-color: #fd9344;
    padding: 0px 80px;
    display: flex;
    justify-content: flex-end;
  }

  .container-nav-info img {
    margin-right: 4px;
  }

  .container-nav-info .nav-content-info {
    margin-right: 32px;
  }

  .container-nav-info .nav-content-info span {
    font-size: 12px;
  }

  .container-content {
    padding: 3px 120px;
  }

  .content-info {
    color: #ffff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    -webkit-text-decoration-line: none;
    text-decoration-line: none;
  }

  .content-info:hover {
    color: #ffffff;
  }

  .icon-nav {
    color: #ce4c36;
  }

  .container-nav-menu {
    height: 105px;
    width: 100%;
    padding: 20px 100px;
  }

  .logo-nav {
    width: 114px;
    height: 80px;
  }

  .nav-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
  }

  .info-hotline {
    font-size: 16px;
    color: #838694;
  }
}

/* Mobile Screen */
@media screen and (max-width: 600px) {
  .container-content,
  .nav-content-info,
  .icon-nav,
  .content-info,
  .container-nav-info,
  .navbar-hotline,
  .container-icon-hotline,
  .icon-hotline,
  .container-navbar-right,
  .container-hotline,
  .info-hotline,
  .content-info {
    display: none !important;
  }

  .navbar-bottom {
    height: 80px;
  }

  .logo-nav {
    height: 60px;
  }

  .nav-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
  }
}

@media screen and (max-width: 376px) {
  .container-content,
  .nav-content-info,
  .icon-nav,
  .content-info,
  .container-nav-info,
  .navbar-hotline,
  .container-icon-hotline,
  .icon-hotline,
  .container-navbar-right,
  .container-hotline,
  .info-hotline,
  .content-info {
    display: none;
  }

  .logo-nav {
    width: 114px;
    height: 80px;
  }

  .nav-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
  }
}

@media screen and (max-width: 361px) {
  .container-content,
  .nav-content-info,
  .icon-nav,
  .content-info,
  .container-nav-info,
  .navbar-hotline,
  .container-icon-hotline,
  .icon-hotline,
  .container-navbar-right,
  .container-hotline,
  .info-hotline,
  .content-info {
    display: none;
  }

  .logo-nav {
    width: 114px;
    height: 80px;
  }

  .nav-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
  }
}

/* Large Screen */
@media screen and (min-width: 480px) {
  .img-title {
    height: 338px;
    width: 100%;
  }
}

/* Mobile Screen */
@media screen and (max-width: 425px) {
  .img-title {
    height: 126px;
    width: 100%;
  }
}

@media screen and (max-width: 376px) {
  .img-title {
    height: 126px;
    width: 100%;
  }
}

@media screen and (max-width: 361px) {
  .img-title {
    height: 126px;
    width: 100%;
  }
}

.title-signup-detail {
  color: #0275d8;
  padding-bottom: 15px;
  border-bottom: 1px solid #0275d8;
}

.border-signup-detail {
  margin: 14px 0px;
  padding: 0px;
  border-bottom: 1px solid #dedede;
}

@media screen and (min-width: 1000px) {
  .form-row {
    margin-top: 50px;
  }
  .container-form {
    margin-bottom: 50px;
    margin-top: 25px;
  }

  .container-card-signup-detail {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

/* Mobile Size */
@media screen and (max-width: 420px) {
  .container-form {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .container-card-signup-detail {
    height: 100%;
  }
}

/* Mobile Size */
@media screen and (max-width: 376px) {
  .container-form {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .container-card-signup-detail {
    height: 100%;
  }
}

@media screen and (max-width: 360px) {
  .container-form {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .container-card-signup-detail {
    height: 100%;
  }
}

.font-italic {
  font-style: italic;
}

.font-weight-bold {
  font-weight: bold;
}

.list-stepform li {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (min-width: 1000px) {
  .btn-verification {
    margin-right: 5rem;
  }
}

/* Mobile Size */
@media screen and (max-width: 420px) {
}

/* Mobile Size */
@media screen and (max-width: 376px) {
}

@media screen and (max-width: 360px) {
}

.container-card-signup-detail {
  padding: 20px 70px 30px 70px;
  margin: auto;
  background: #f0f4ff;
}

.card-signup-detail {
  box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 10%);
}

.icon-next {
  color: #dedede;
}

@media screen and (min-width: 1000px) {
  .stepper {
    width: 100%;
  }
  .step-label {
    font-family: "Museo Sans 700", Arial;
    color: "#AAAAAA";
    font-size: 14px;
  }

  .logo-signup-page {
    max-height: 100px;
  }

  .card-signup-detail {
    padding: 0px 50px;
    min-height: 800px;
  }

  .btn-back {
    width: 10rem;
    margin-right: 5rem;
  }
  .btn-next {
    width: 150px;
  }
}

/* Mobile Size */
@media screen and (max-width: 420px) {
  .stepper {
    width: 100%;
  }
  .step-label {
    display: none;
  }

  .logo-signup-page {
    max-height: 80px;
  }

  .card-signup-detail {
    padding: 0px 20px;
    min-height: 850px;
  }
}

/* Mobile Size */
@media screen and (max-width: 376px) {
  .stepper {
    width: 100%;
  }
  .step-label {
    display: none;
  }

  .logo-signup-page {
    max-height: 80px;
  }

  .card-signup-detail {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 360px) {
  .stepper {
    width: 100%;
  }
  .step-label {
    display: none;
  }

  .logo-signup-page {
    max-height: 80px;
  }

  .card-signup-detail {
    padding: 0px 20px;
  }
}

.container-footer * {
  overflow-x: hidden;
}

.container-footer .input-email {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  padding: 6px;
  padding-left: 60px;
}

.tooltip-link {
  font-size: 14px;
  padding: 6px 10px;
  transition: 0.2s;
  display: block;
  text-decoration: none;
  color: #848484;
  border-bottom: 1px solid #84848495;
}

.tooltip-link:hover {
  color: rgb(242, 155, 68);
}

/* Large Screen */
@media screen and (min-width: 600px) {
  .container-footer {
    /* height: 779px; */
    background: #1b1f2e;
    width: 100%;
    padding: 0px 10px;
    background-image: url(https://gaviaspreview.com/wp/krowd/wp-content/uploads/2015/12/bg-footer.png);
    background-repeat: no-repeat;
  }

  .container-content-footer {
    padding: 40px 0px 90px;
    border-bottom: 1px solid #333333;
    display: flex;
    justify-content: space-around;
  }

  .logo-campus {
    /* height: 185px; */
    width: 212px;
    margin-bottom: -6px;
  }

  .logo-org {
    width: 250px;
    margin-bottom: 10px;
  }

  .text-footer {
    color: #8d91a2;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    width: 308px;
    line-height: 230%;
  }

  .title-footer {
    color: #ffffff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    width: 343px;
  }

  .footer-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none;
    transition: 0.2s;
  }

  .footer-menu:hover {
    color: #fd9344;
  }

  .icon-footer {
    color: #ce4c36;
    background-image: #ce4c36;
    height: px;
    width: 50px;
  }

  .join-text {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    margin-top: 20px;
  }

  .container-mail {
    padding: 3px;
    width: 343px;
    height: 74px;
    border: 1px solid rgba(124, 120, 120, 0.432);
    border: 1px solid rgba(252, 249, 249, 0.082);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .form-email,
  .form-email:focus {
    background: #1b1f2e;
    border: none;
    border-radius: 0;
    color: #ffffff;
  }

  .container-contact,
  .container-sitemap {
    margin-top: 30px;
    margin-left: 30px;
  }

  .social-media {
    margin-top: 40px !important;
    margin-left: -10px;
    overflow: hidden;
    width: 100%;
  }

  .icon-social {
    color: #1b1f2e;
    background: #ffffff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transition: -webkit-transform 0.3s cubic-bezier(0, 0.64, 0.19, 2.85);
    transition: transform 0.3s cubic-bezier(0, 0.64, 0.19, 2.85);
    transition: transform 0.3s cubic-bezier(0, 0.64, 0.19, 2.85), -webkit-transform 0.3s cubic-bezier(0, 0.64, 0.19, 2.85);
  }

  .icon-social:hover {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    background: #fd9344;
    color: inherit;
  }

  .social-media {
    margin-top: 15px;
  }

  .copyright {
    /* margin: 30px auto; */
    border-top: 1px solid #8d91a228;
    padding: 40px;
    display: flex;
    justify-content: center;
  }

  .col-icon-footer {
    display: flex;
    align-items: center;
  }
}

/* Mobile Screen */
@media screen and (max-width: 600px) {
  .container-footer {
    min-height: 1000px;
    background: #1b1f2e;
    width: 100%;
    padding: 0px 20px 10px;
  }

  .container-content-footer {
    padding: 40px 0px 20px;
    border-bottom: 1px solid #333333;
  }

  .logo-campus {
    height: 185px;
    width: 262px;
  }

  .logo-org {
    width: 231px;
    margin-bottom: 20px;
  }

  .text-footer {
    color: #8d91a2;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    width: 100%;
    line-height: 200%;
  }

  .title-footer {
    color: #ffffff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    width: 343px;
  }

  .footer-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .icon-footer {
    color: #ce4c36;
    background-image: #ce4c36;
    height: px;
    width: 50px;
  }

  .join-text {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
  }

  .container-mail {
    padding: 3px;
    width: 343px;
    height: 74px;
    border: 1px solid rgba(124, 120, 120, 0.432);
    border: 1px solid rgba(252, 249, 249, 0.082);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .form-email,
  .form-email:focus {
    background: #1b1f2e;
    border: none;
    border-radius: 0;
    color: #ffffff;
  }

  .container-contact,
  .container-sitemap {
    margin-top: 50px;
    margin-left: 0px;
  }

  .social-media {
    width: 100%;
  }

  .icon-social {
    color: #1b1f2e;
    background: #ffffff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .social-media {
    margin-top: 15px;
  }

  .container-join {
    margin-top: 50px;
  }

  .copyright {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .copyright span {
    font-size: small;
  }
}

@media screen and (max-width: 376px) {
  .container-footer {
    min-height: 1000px;
    background: #1b1f2e;
    width: 100%;
    padding: 0px 20px 10px;
  }

  .container-content-footer {
    padding: 40px 0px 20px;
    border-bottom: 1px solid #333333;
  }

  .logo-campus {
    height: 185px;
    width: 262px;
  }

  .logo-org {
    width: 231px;
    height: 138px;
  }

  .text-footer {
    color: #8d91a2;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    width: 308px;
    line-height: 200%;
  }

  .title-footer {
    color: #ffffff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    width: 343px;
  }

  .footer-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .icon-footer {
    color: #ce4c36;
    background-image: #ce4c36;
    height: px;
    width: 50px;
  }

  .join-text {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
  }

  .container-mail {
    padding: 3px;
    width: 320px;
    height: 74px;
    border: 1px solid rgba(124, 120, 120, 0.432);
    border: 1px solid rgba(252, 249, 249, 0.082);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .form-email,
  .form-email:focus {
    background: #1b1f2e;
    border: none;
    border-radius: 0;
    color: #ffffff;
  }

  .container-contact,
  .container-sitemap {
    /* margin-top: 50px; */
    margin-left: 0px;
  }

  .social-media {
    width: 100%;
  }

  .icon-social {
    color: #1b1f2e;
    background: #ffffff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .social-media {
    margin-top: 15px;
  }

  .container-join {
    margin-top: 50px;
  }

  .copyright {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .copyright span {
    font-size: small;
  }
}

@media screen and (max-width: 361px) {
  .container-footer {
    min-height: 1000px;
    background: #1b1f2e;
    width: 100%;
    padding: 0px 20px 10px;
  }

  .container-content-footer {
    padding: 40px 0px 20px;
    border-bottom: 1px solid #333333;
  }

  .logo-campus {
    height: 185px;
    width: 262px;
  }

  .logo-org {
    width: 231px;
    height: 138px;
  }

  .text-footer {
    color: #8d91a2;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    width: 308px;
    line-height: 200%;
  }

  .title-footer {
    color: #ffffff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    width: 343px;
  }

  .footer-menu {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #838694;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .icon-footer {
    color: #ce4c36;
    background-image: #ce4c36;
    height: px;
    width: 50px;
  }

  .join-text {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
  }

  .container-mail {
    padding: 3px;
    width: 300px;
    height: 74px;
    border: 1px solid rgba(124, 120, 120, 0.432);
    border: 1px solid rgba(252, 249, 249, 0.082);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }

  .form-email,
  .form-email:focus {
    background: #1b1f2e;
    border: none;
    border-radius: 0;
    color: #ffffff;
  }

  .container-contact,
  .container-sitemap {
    margin-top: 50px;
    margin-left: 0px;
  }

  .social-media {
    width: 100%;
    overflow: hidden;
  }

  .icon-social {
    color: #1b1f2e;
    background: #ffffff;
    border-radius: 100%;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .social-media {
    margin-top: 15px;
  }

  .container-join {
    margin-top: 50px;
  }

  .copyright {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .copyright span {
    font-size: small;
  }
}

.banner-complete .background {
  width: 100%;
  height: 585px;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-complete .pillar-item {
  background-color: #fd9344;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
  box-shadow: 0px 8px 24px rgba(37, 37, 37, 0.12);
  position: absolute;
  margin-top: -35px;
  margin-left: 85px;
  height: 145px;
  width: 220px;
}

.banner-complete .pillar-item h4 {
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.banner-complete .pillar-item ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.banner-complete .pillar-item ul li {
  font-size: 14px;
  margin-right: 40px;
}

@media (max-width: 600px) {
  .banner-complete .background {
    height: 300px;
    background-position: center;
  }
}

.complete-form.impact-care textarea {
  height: 131px;
}

.banner-enquiry {
  display: flex;
  width: 100%;
}

.banner-enquiry div:nth-child(1) {
  width: 46%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 63px;
  padding-bottom: 163px;
}

.banner-enquiry h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.banner-enquiry p {
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.banner-enquiry div:nth-child(2) {
  width: 54%;
}

@media (max-width: 600px) {
  .banner-enquiry {
    flex-direction: column-reverse;
  }

  .banner-enquiry div:nth-child(1) {
    padding: 20px 40px;
    padding-bottom: 110px;
    width: 100%;
  }

  .banner-enquiry div:nth-child(2) {
    width: 100%;
  }

  .banner-enquiry h4 {
    font-size: 30px;
    line-height: 150%;
  }

  .banner-enquiry p {
    font-size: 16px;
    margin-top: 5px;
  }
}

