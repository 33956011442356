.banner-complete .background {
  width: 100%;
  height: 585px;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-complete .pillar-item {
  background-color: #fd9344;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
  box-shadow: 0px 8px 24px rgba(37, 37, 37, 0.12);
  position: absolute;
  margin-top: -35px;
  margin-left: 85px;
  height: 145px;
  width: 220px;
}

.banner-complete .pillar-item h4 {
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.banner-complete .pillar-item ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.banner-complete .pillar-item ul li {
  font-size: 14px;
  margin-right: 40px;
}

@media (max-width: 600px) {
  .banner-complete .background {
    height: 300px;
    background-position: center;
  }
}
